@import "../../../variables.scss";

.cardSkill {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  animation: fadeInSkill 500ms linear forwards;
  animation-delay: 2.5s;
  margin: 0;
  opacity: 0;

  &:nth-child(2) {
    animation-delay: 2.75s;

    .circle {
      svg {
        circle {
          animation-delay: 2.75s;
        }
      }
    }
  }

  &:nth-child(3) {
    animation-delay: 3s;

    .circle {
      svg {
        circle {
          animation-delay: 3s;
        }
      }
    }
  }

  &:nth-child(4) {
    animation-delay: 3.25s;

    .circle {
      svg {
        circle {
          animation-delay: 3.25s;
        }
      }
    }
  }

  &:nth-child(5) {
    animation-delay: 3.5s;

    .circle {
      svg {
        circle {
          animation-delay: 3.5s;
        }
      }
    }
  }

  &:nth-child(6) {
    animation-delay: 3.75s;

    .circle {
      svg {
        circle {
          animation-delay: 3.75s;
        }
      }
    }
  }

  &:nth-child(7) {
    animation-delay: 4s;

    .circle {
      svg {
        circle {
          animation-delay: 4s;
        }
      }
    }
  }

  &:nth-child(8) {
    animation-delay: 4.25s;

    .circle {
      svg {
        circle {
          animation-delay: 4.25s;
        }
      }
    }
  }

  &:nth-child(9) {
    animation-delay: 4.5s;

    .circle {
      svg {
        circle {
          animation-delay: 4.5s;
        }
      }
    }
  }

  &:nth-child(10) {
    animation-delay: 4.75s;

    .circle {
      svg {
        circle {
          animation-delay: 4.75s;
        }
      }
    }
  }

  @include widthQuery(930px) {
    animation-delay: 1.8s;
  }

  h4 {
    font-weight: 600;
    color: #747d8c;
    z-index: 6;

    @include heightQuery(735px) {
      transform: translateY(-60px);
      font-size: 0.8em;
    }
  }

  .circle {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    z-index: 5;

    svg {
      transform: rotate(270deg);
      width: 150px;
      height: 150px;
      border-radius: 50%;

      circle {
        width: 100%;
        height: 100%;
        fill: none !important;
        stroke-width: 7;
        stroke-linecap: round;
        transform: translate(5px, 5px);
        stroke-dasharray: 440;
        animation: fillLine 750ms linear forwards;
        animation-delay: 2.5s;

        @include widthQuery(930px) {
          animation-delay: 1.8s;
        }
      }
    }

    .inside {
      position: absolute;
      width: calc(100% - 40px); /* Adjust this to keep some space inside */
      height: calc(100% - 40px); /* Adjust this to keep some space inside */
      top: 20px; /* Adjust this to keep some space inside */
      left: 20px; /* Adjust this to keep some space inside */
      background-color: #e6e7e7;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #f1f2f6;
        border-radius: 50%;
        transform: scale(1.27);
        z-index: -1;
      }

      span {
        font-size: 24px;
        color: #747d8c;
      }
    }

    .dot {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;

      &::before {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #747d8c;
        top: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

@keyframes fillLine {
  from {
    stroke-dashoffset: 440;
  }
}

@keyframes fadeInSkill {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
