.social-container {
  z-index: 2;
  position: absolute;

  p {
    font-size: 18px;
  }

  ul {
    list-style: none;

    li {
      display: inline-block;
      margin-right: 30px;
      transition: 200ms ease-in-out;

      a {
        text-decoration: none;
        font-size: 25px;
      }
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
}
