.fullWidthContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.459);
    z-index: 10;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .modal {
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 350px;
        background-color: #f5f5f5;
        border-radius: 10px;
        padding: 100px 25px 25px 25px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            width: 250px;
            height: 250px;
            position: absolute;
            left: 50%;
            top: -50%;
            transform: translate(-50%, 20%);
        }

        h1 {
            text-align: center;
            margin-bottom: 25px;
            font-weight: 300;
            color: #2a2a33;
        }

        .successText {
            text-align: center;
            color: #2a2a33;
            font-size: 0.95em;
        }

        button {
            width: 100%;
            max-width: 350px;
            height: 50px;
            margin-top: 35px;
            background-color: #70C340;
            border: none;
            cursor: pointer;
            border-radius: 10px;
            font-size: 1.2em;
            transition: background-color 250ms ease-in-out;

            &:hover {
                background-color: #95d371;
            }
        }
    }
}