:root {
  --side-nav-background: rgba(255, 255, 255, 0.089);
}

@mixin widthQuery($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin heightQuery($height) {
  @media only screen and (max-height: $height) {
    @content;
  }
}

@mixin miniSize($width, $height) {
  @media only screen and (max-width: $width),
  screen and (max-height: $height)
  {
    @content;
  }
}
