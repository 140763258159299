@import "../../../variables.scss";

.viewContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.904);
  backdrop-filter: blur(5px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .viewMain {
    width: 100%;
    max-width: 1700px;
    background-color: rgb(255, 255, 255);
    height: 100%;
    max-height: 900px;
    border-radius: 20px;
    position: relative;
    display: flex;

    .leftSideView {
      width: 70% !important;
      background-color: rgb(255, 255, 255);
      border-radius: 20px 0px 0px 20px;
      padding: 50px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include widthQuery(1050px) {
        display: none;
      }

      @include heightQuery(830px) {
        display: none;
      }

      .titleView {
        color: rgb(102, 102, 102);
        text-align: center;

        @include widthQuery(1200px) {
          font-size: 28px;
        }

        @include heightQuery(870px) {
          font-size: 28px;
        }
      }

      .infoView {
        color: rgb(102, 102, 102);
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        line-height: 1.6;

        @include widthQuery(1200px) {
          font-size: 16px;
        }

        @include heightQuery(870px) {
          font-size: 16px;
          margin-top: 10px;
        }
      }

      .githubLink {
        color: rgb(255, 102, 0);
        text-decoration: none;
        text-align: center;
        font-size: 18px;
      }

      .languagesContainer {
        width: 100%;
        margin-top: 70px;
        display: flex;
        flex-direction: column;

        @include heightQuery(970px) {
          margin-top: 10px;
        }

        .languagesHeader {
          text-align: center;
          color: rgb(102, 102, 102);
          font-size: 18px;
        }

        .stackListContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .stackList {
            list-style-type: none;
            display: inline-block;
            margin-top: 20px;
            font-size: 14px;
            display: flex;

            .stackItems {
              color: white;
              background-color: rgb(255, 102, 0);
              border-radius: 5px;
              padding: 3px 10px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .rightSideView {
      width: 600px;
      height: 100%;
      background-color: rgb(245, 245, 245);
      border-radius: 0px 20px 20px 0px;
      position: relative;

      @include widthQuery(1050px) {
        border-radius: 20px 20px 20px 20px;
        width: 100%;
      }

      @include heightQuery(830px) {
        border-radius: 20px 20px 20px 20px;
        width: 100%;
      }

      .closeBtnView {
        position: absolute;
        right: 0;
        top: 0;
        background-color: rgb(255, 102, 0);
        height: 45px;
        width: 45px;
        border-radius: 0px 20px;
        cursor: pointer;
        z-index: 40;

        .line1All {
          width: 3px;
          height: 20px;
          background-color: white;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          margin: auto;
          transform: translateY(-50%) rotate(45deg);
        }

        .line2All {
          width: 3px;
          height: 20px;
          background-color: white;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          margin: auto;
          transform: translateY(-50%) rotate(-45deg);
        }
      }

      .swiper {
        height: 100% !important;
        max-height: 900px !important;
        width: 100% !important;
        max-width: 600px !important;
        margin: 0px !important;
        padding: 0px;
        border-radius: 0px 20px 20px 0px;
        cursor: grab;

        @include widthQuery(1050px) {
          max-width: 100% !important;
        }

        @include heightQuery(830px) {
          max-width: 100% !important;
        }

        .swiper-pagination {
          background-color: rgba(255, 255, 255, 0.863);
          border-radius: 20px;
          width: fit-content;
          padding: 1px 5px;
          margin-bottom: 10px;
        }

        .swiper-slide {
          margin: 0px;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgb(245, 245, 245);
          overflow: hidden;

          @include widthQuery(1050px) {
            border-radius: 20px 20px 20px 20px;
          }

          @include heightQuery(830px) {
            border-radius: 20px 20px 20px 20px;
          }

          img {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;

            @include widthQuery(1050px) {
              border-radius: 5px;
              object-fit: contain;
            }

            @include heightQuery(830px) {
              border-radius: 5px;
              object-fit: contain;
            }
          }
        }
      }

      .viewDetails {
        z-index: 30;
        position: absolute;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        height: max-content;
        display: none;

        @include widthQuery(1050px) {
          display: block;
        }

        @include heightQuery(830px) {
          display: block;
        }

        .githubLinkMiniImage {
          color: white;
          background-color: rgb(255, 102, 0);
          padding: 10px;
          text-decoration: none;
          text-align: center;
          font-size: 15px;
          border-radius: 20px;
        }

        .detailsTextBtn {
          margin-top: 20px;
          color: rgb(255, 102, 0);
          background-color: rgb(255, 255, 255);
          padding: 10px;
          text-decoration: none;
          text-align: center;
          font-size: 15px;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }

    .miniInfo {
      position: absolute;
      color: black;
      inset: 0;
      background-color: rgb(255, 255, 255);
      z-index: 30;
      border-radius: 20px;
      backdrop-filter: blur(5px);
      text-align: center;
      padding: 50px 30px;
      display: none;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
      transition: 250ms ease-in-out;

      &::-webkit-scrollbar {
        display: block;
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: rgb(255, 102, 0);
        border-right: none;
        border-left: none;
      }

      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 20px;
      }

      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 20px;
      }

      @include miniSize(1050px, 830px) {
        display: flex;
      }

      .closeMiniContainer {
        position: fixed;
        background-color: white;
        height: 45px;
        width: 45px;
        border-radius: 20px 0px;
        left: 0;
        top: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: rgb(255, 102, 0);
          height: 20px;
          width: 20px;
          stroke-width: 1;
          stroke: rgb(255, 102, 0);
        }
      }

      .titleViewMini {
        color: rgb(102, 102, 102);
        text-align: center;

        @include widthQuery(1200px) {
          font-size: 28px;
        }

        @include heightQuery(870px) {
          font-size: 28px;
        }
      }

      .infoViewMini {
        color: rgb(102, 102, 102);
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        line-height: 1.6;

        @include widthQuery(1200px) {
          font-size: 16px;
        }

        @include heightQuery(870px) {
          font-size: 16px;
          margin-top: 10px;
        }
      }

      .githubLinkMini {
        color: rgb(255, 102, 0);
        text-decoration: none;
        text-align: center;
        font-size: 18px;
      }

      .languagesContainerMini {
        width: 100%;
        margin-top: 70px;
        display: flex;
        flex-direction: column;

        @include heightQuery(970px) {
          margin-top: 10px;
        }

        .languagesHeaderMini {
          text-align: center;
          color: rgb(102, 102, 102);
          font-size: 18px;
        }

        .stackListContainerMini {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .stackListMini {
            list-style-type: none;
            display: inline-block;
            margin-top: 20px;
            font-size: 14px;
            display: flex;

            .stackItemsMini {
              color: white;
              background-color: rgb(255, 102, 0);
              border-radius: 5px;
              padding: 3px 10px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
