@import "../../variables.scss";

nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  position: absolute;
  top: 0px;
  z-index: 2;

  @include widthQuery(780px) {
    justify-content: center;
  }

  @include heightQuery(430px) {
    justify-content: center;
  }

  p {
    color: white;
    font-weight: bolder;
    font-size: 40px;
    font-family: "Sora", sans-serif;
    cursor: default;
    transition: 250ms ease-in-out;

    @include widthQuery(630px) {
      font-size: 30px;
    }

    @include heightQuery(430px) {
      font-size: 25px;
    }

    span {
      font-weight: 10;
    }
    .dot {
      color: #fa3f3b;
      font-weight: bolder;
    }
  }
}
