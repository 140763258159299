@import "../../variables.scss";

.bars-cont {
  height: 20px;
  width: 20px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  cursor: pointer;

  .bar1,
  .bar2,
  .bar3 {
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background-color: #fa3f3b;
  }
}

.bar-container {
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--side-nav-background);
  border: 1px solid rgba(255, 255, 255, 0.089);
  width: 60px;
  height: 350px;
  border-radius: 50px;
  z-index: 8;

  /*   @include widthQuery(930px) {
    right: 0;
    left: 0;
    transform: translateY(-100%);
    top: 100%;
    width: 100%;
    flex-direction: row;
    height: 60px;
    border-radius: 0;
    backdrop-filter: blur(10px);
  }

  @include heightQuery(430px) {
    flex-direction: column;
    width: 60px;
    left: auto;
    right: 0px;
    height: 100%;
    border-radius: 0;
    backdrop-filter: blur(10px);
  } */

  @include widthQuery(930px) {
    height: 100%;
    border-radius: 0px;
    right: 0px;
    backdrop-filter: blur(10px);
  }

  @include heightQuery(430px) {
    flex-direction: column;
    width: 60px;
    left: auto;
    right: 0px;
    height: 100%;
    border-radius: 0;
    backdrop-filter: blur(10px);
  }

  .bars-cont-close {
    height: 20px;
    width: 20px;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .bar1-close {
      position: absolute;
      height: 4px;
      width: 20px;
      border-radius: 2px;
      background-color: #fa3f3b;
      transform: rotate(45deg);
    }

    .bar2-close {
      position: absolute;
      height: 4px;
      width: 20px;
      border-radius: 2px;
      background-color: #fa3f3b;
      transform: rotate(-45deg);
    }
  }

  .bar {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    transition: 250ms ease-in-out;
    text-decoration: none;
    position: relative;
    font-size: 20px;

    @include widthQuery(930px) {
      margin-bottom: 0px;
    }

    &:nth-child(1) {
      margin-top: 20px;

      @include widthQuery(930px) {
        margin-top: 0px;
      }
    }

    &:nth-child(5) {
      margin-bottom: 20px;

      @include widthQuery(930px) {
        margin-bottom: 0px;
      }
    }

    div {
      display: none;
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 13px;
      background-color: var(--side-nav-background);
      backdrop-filter: blur(10px);
      padding: 5px;
      border-radius: 2px;
      z-index: 3;
      white-space: nowrap;
    }

    &:hover {
      color: #fa3e3b7c;
    }

    &:hover div {
      display: block;

      @include widthQuery(930px) {
        display: none;
      }

      @include heightQuery(430px) {
        display: none;
      }
    }
  }

  .bar:nth-child(1) > .active > i {
    color: #fa3f3b !important;
    transition: 250ms ease-in-out;
  }

  .bar:nth-child(2) > .active > i {
    color: #a4d6d8 !important;
    transition: 250ms ease-in-out;
  }

  .bar:nth-child(3) > .active > i {
    color: #6e7c87 !important;
    transition: 250ms ease-in-out;
  }

  .bar:nth-child(4) > .active > i {
    color: #ff4400 !important;
    transition: 250ms ease-in-out;
  }

  .bar:nth-child(5) > .active > i {
    color: #6b2025 !important;
    transition: 250ms ease-in-out;
  }
}

.skills-active {
  background-color: #d3d3d36b !important;
}
