@import "../../variables.scss";

.projectsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background-color: #1d1d1d;
/*   background-color: #D96846;
 */  overflow-x: hidden;

  .maxWidthProjects {
    max-width: 1700px;
    max-height: 300px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 90px 50px 50px;
    animation: 2s slideToTopProjects forwards;
    animation-delay: 1.5s;

    @include widthQuery(930px) {
      padding: 50px;
    }

    @include heightQuery(430px) {
      padding: 50px 110px 50px 50px;
    }

    .title-holder {
      .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          text-align: center;
        }
      }
    }

    .swiper {
      width: 0%;
      height: 0px;
      max-height: 100%;
      margin-top: 50px;
      margin-bottom: 50px;
      opacity: 0;
      animation: 1.5s fadeProjects forwards;
      animation-delay: 500ms;

      .swiper-pagination {
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
        border-radius: 10px;
        opacity: 1;
      }

      .swiper-pagination-bullet {
        transition: 250ms ease-in-out;
      }

      .swiper-pagination-bullet-active {
        background-color: #fa3f3b;
      }

      .blog-slider {
        text-align: center;
        font-size: 18px;
        background: #fff;
        height: 100%;
        width: 30%;
        border-radius: 12px;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;

        &__img {
          width: 100%;
          flex-shrink: 0;
          height: 100%;
          max-height: 500px;
          box-shadow: 4px 13px 30px 1px #9e38fd11;
          /* position: relative; */

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            transition: all 0.3s;
            cursor: pointer;
            border-radius: 12px;
          }
        }

        &__content {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          cursor: grab;
          background-color: #000000ee;
          backdrop-filter: blur(3px);
          opacity: 0;
          transition: 250ms ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-radius: 12px;
          max-height: 500px;

          &:hover {
            opacity: 1;
          }

          .holder {
            width: 100%;
            transform: translateY(-80px);

            .__code {
              text-align: center;
              color: #666666;
            }

            .__title {
              font-size: 24px;
              font-weight: 700;
              color: #ffffff;
              z-index: 2;
              text-align: center;
            }

            .__text {
              position: absolute;
              color: #ffffff;
              line-height: 1.5em;
              padding: 30px;
              text-align: center;
            }
          }
        }

        &__button {
          position: absolute;
          right: 30px;
          bottom: 30px;
          cursor: pointer;
          text-decoration: none;
          color: #fa3f3b;
        }
      }
    }
  }
}

@keyframes fadeProjects {
  0% {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
  65% {
    opacity: 0;
    height: 530px;
    width: 0px;
  }
  100% {
    opacity: 1;
    width: 100%;
    height: 530px;
  }
}

// New animations

@keyframes slideToTopProjects {
  0% {
    max-height: 100px;
  }
  100% {
    max-height: 100%;
  }
}
