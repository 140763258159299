@import "../../variables.scss";

.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: min-content;
  min-height: 100vh;
  background-color: #8f3035;

  @include widthQuery(930px) {
    overflow-y: hidden;
  }

  .maxWidthContact {
    max-width: 1700px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 90px 50px 50px;
    position: relative;

    @include widthQuery(930px) {
      flex-direction: column-reverse;
      padding: 50px;
    }

    @media only screen and (max-height: 430px) and (max-width: 930px) {
      padding: 50px 90px 50px 50px;
    }

    form {
      width: 0%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      padding: 0px 50px;
      opacity: 0;
      animation: form 1.5s forwards;
      animation-delay: 1s;

      @include widthQuery(930px) {
        padding: 0px;
        margin-top: 50px;
      }

      .social-container {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);

        @include widthQuery(930px) {
          position: initial;
          transform: translateX(10%);
          margin: 0 auto;
          margin-bottom: 50px;
        }

        @media only screen and (max-height: 700px) and (min-width: 930px) {
          bottom: 0px;
          margin: 10px 0px;
        }
      }

      input {
        margin-bottom: 20px;
        height: 50px;
        outline: none;
        border: none;
        border-bottom: 2px solid rgba(255, 255, 255, 0.4);

        background-color: transparent;

        padding: 0px 10px;

        transition: 250ms ease-in-out;

        &::placeholder {
          color: rgba(255, 255, 255, 0.4);
        }

        &:focus {
          background-color: rgba(255, 255, 255, 0.062);
        }
      }

      textarea {
        margin-bottom: 50px;
        margin-top: 30px;
        height: 150px;
        outline: none;
        border: none;
        border-bottom: 2px solid rgba(255, 255, 255, 0.4);

        background-color: transparent;
        padding: 10px;
        font-family: "Roboto", sans-serif;
        transition: 250ms ease-in-out;
        resize: none;

        &::placeholder {
          color: rgba(255, 255, 255, 0.4);
        }

        &:focus {
          background-color: rgba(255, 255, 255, 0.062);
        }
      }

      button {
        border: 1px solid white;
        padding: 15px 70px;
        border-radius: 50px;
        cursor: pointer;
        background-color: transparent;
        transition: background-color 200ms ease-in-out;
        text-decoration: none;

        &:hover {
          background-color: rgba(255, 255, 255, 0.116);
        }

        @include widthQuery(930px) {
          margin-bottom: 50px;
        }
      }
    }
  }
}

.errorInput {
  border-bottom: 2px solid rgb(255, 102, 102) !important;
  animation: errorAnimation 250ms;

  &::placeholder {
    color: rgb(255, 102, 102) !important;
  }
}

@keyframes form {
  0% {
    width: 0%;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes errorAnimation {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
