@import "../../variables.scss";

.header-container-notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;

  @include widthQuery(930px) {
    overflow-y: scroll;
    height: min-content;
  }

  .particles {
    position: absolute;
    left: 30%;
    right: 0px;
    top: 0px;
    bottom: 0px;

    @include widthQuery(930px) {
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      z-index: -2;
    }
  }

  .maxWidth-notfound {
    max-width: 1700px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0px 50px;

    nav {
      @include widthQuery(930px) {
        justify-content: center;
        align-items: center;
      }
    }

    @include widthQuery(930px) {
      flex-direction: column-reverse;
      justify-content: center;
      margin: 0px;
      height: 100%;
    }

    @include heightQuery(630px) {
      flex-direction: row;
      justify-content: space-around;
    }

    .social-container {
      top: 50px;
      right: 0px;

      @include widthQuery(930px) {
        display: none;
      }

      @include heightQuery(630px) {
        display: none;
      }
    }

    .left-side-notfound {
      max-width: 50%;
      width: 100%;
      text-align: center;

      @include widthQuery(930px) {
        max-width: 100%;
        margin-bottom: 100px;
        padding: 20px;
      }

      @include heightQuery(630px) {
      }

      .name-container-notfound {
        .error-num {
          color: #fa3f3b;
          font-size: 6em;
          font-weight: 800;
          margin-bottom: 20px;

          @include widthQuery(930px) {
            font-size: 3em;
          }
        }
        .headertxt {
          font-size: 2em;
          font-weight: 800;

          @include widthQuery(930px) {
            font-size: 1.5em;
          }

          span {
            color: #fa3f3b;
          }
        }
      }

      .call-to-action-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        margin-top: 20px;
        .title {
          font-size: 1.2em;

          @include widthQuery(930px) {
            font-size: 1em;
          }
        }

        .cntbtn-notfound {
          margin-top: 40px;

          a {
            text-decoration: none;
            font-size: 1.4em;
            padding: 15px 25px;
            background-color: #fa3f3b;
            width: fit-content;
            border-radius: 50px;
            transition: 250ms ease-in-out;

            @include widthQuery(930px) {
              font-size: 1em;
              z-index: 1;
            }

            &:hover {
              background-color: #cc3532;
            }
          }
        }
      }
    }

    .right-side-notfound {
      width: 100%;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include widthQuery(930px) {
        max-width: 100%;
        padding: 20px;
        z-index: -2;
        margin-top: 50px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 100%;

        svg {
          max-width: 100%;
        }
      }
    }
  }
}
