@import "../../variables.scss";

.skills-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  /* background-color: #1c1c1c; */
  background-color: #f5f5f5;

  .maxWidthSkills {
    max-width: 1700px;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 90px 50px 50px;

    @include widthQuery(930px) {
      padding: 50px 50px 75px 50px;
    }

    @include heightQuery(430px) {
      padding: 50px 75px 50px 25px;
    }

    .title-holder {
      display: block;
      transform: translateY(150%);
      animation: moveTitleHolder 500ms linear forwards;
      animation-delay: 1s;

      @include widthQuery(930px) {
        transform: translateY(0%);
        animation: none;
      }

      .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          color: #2a2a33;

          @include widthQuery(369px) {
            font-size: 20px;
          }
        }

        .info {
          color: #2a2a33;
          text-align: center;
        }

        .page {
          ul {
            li {
              div {
                background-color: #2a2a33;
              }

              p {
                color: #2a2a33;
              }
            }
          }
        }
      }
    }

    .grid-holder {
      margin-top: 100px;
      width: 100%;
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      max-width: 950px;
      grid-gap: 50px;

      @include heightQuery(735px) {
        margin-top: 30px;
        gap: 0px 50px;
      }
    }
  }
}

/* @keyframes slideToTop {
  0% {
    max-height: 300px;
  }
  100% {
    min-height: 100vh;
  }
} */

@keyframes popSkills {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes heightChange {
  0% {
    height: 0px;
  }
  100% {
    display: 100%;
  }
}

@keyframes moveTitleHolder {
  0% {
    transform: translateY(150%); /* Vertically center initially */
  }
  to {
    transform: translateY(0%); /* Return to original position */
  }
}
