@import "../../variables.scss";

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;

  .particles {
    position: absolute;
    left: 30%;
    right: 0px;
    top: 0px;
    bottom: 0px;

    @include widthQuery(780px) {
      left: 0px;
      right: 0px;
      top: 30%;
      bottom: 0px;
    }

/*     @include heightQuery(430px) {
      display: none;
    } */
  }

  .maxWidth {
    max-width: 1700px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0px 50px;

    @include widthQuery(930px) {
      flex-direction: column-reverse;
      justify-content: center;
    }

    @include heightQuery(630px) {
      flex-direction: row;
      justify-content: space-around;
    }

    .social-container {
      top: 50px;
      right: 0px;

      @include widthQuery(780px) {
        display: none;
      }

      @include heightQuery(630px) {
        display: none;
      }
    }

    .left-side {
      .name-container {
        border-left: 3px solid white;
        padding-left: 10px;
        animation: 2s slideright;

        @include widthQuery(930px) {
          border-left: none;
          margin-top: 50px;
        }

        .headertxt {
          font-size: 50px;
          font-weight: bolder;
          transition: 250ms ease-in-out;

          @include widthQuery(1300px) {
            font-size: 35px;
          }

          @include heightQuery(630px) {
            font-size: 35px;
            margin-top: 50px;
          }

          @include widthQuery(630px) {
            font-size: 25px;
          }

          @include widthQuery(930px) {
            text-align: center;
          }

          @include heightQuery(380px) {
            font-size: 21px;
          }

          @include heightQuery(430px) {
            text-align: start;
            font-size: 25px;
          }

          @include widthQuery(480px) {
            font-size: 19px;
          }

          @include widthQuery(400px) {
            font-size: 17px;
          }

          span {
            color: #fa3f3b;
          }
        }
      }

      .call-to-action-container {
        padding-top: 30px;
        padding-left: 13px;
        animation: 2s fade;

        @include widthQuery(930px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        @include heightQuery(480px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .title {
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 40px;
          transition: 250ms ease-in-out;

          @include widthQuery(480px) {
            font-size: 15px;
          }

          @include heightQuery(430px) {
            font-size: 20px;
          }

          @include heightQuery(400px) {
            font-size: 15px;
          }
        }

        .cntbtn {
          border: 1px solid rgba(255, 255, 255, 0.274);
          padding: 15px 70px;
          width: max-content;
          border-radius: 50px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
          display: block;

          a {
            text-decoration: none;
            border-radius: 50px;
          }

          &:hover {
            .wave {
              top: 220px;
            }
          }

          span {
            position: relative;
            z-index: 1;
            color: white;
            font-size: 15px;
            letter-spacing: 8px;
          }

          .wave {
            width: 100%;
            height: 200px;
            background-color: #ffffff17;
            box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
            position: absolute;
            left: 0;
            top: -80px;
            transition: 0.4s;

            &::before,
            &::after {
              width: 200%;
              height: 200%;
              content: "";
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, -75%);
            }

            &::before {
              border-radius: 45%;
              background-color: rgb(255, 122, 33);
              animation: wave 5s linear infinite;
            }

            &::after {
              border-radius: 40%;
              background-color: rgba(247, 61, 54, 0.5);
              animation: wave 10s linear infinite;
            }
          }
        }
      }
    }

    .right-side {
      z-index: 0;
      display: flex;
      flex: 1;
      justify-content: center;
      height: 100%;
      width: 100%;
      position: relative;

      @include widthQuery(930px) {
        max-height: 50%;
        align-items: center;
        margin-top: 50px;
      }

      .profileImage {
        width: 100%;
        height: 100%;
        position: relative;

        @include widthQuery(930px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          width: auto;
          height: 100%;
          position: absolute;
          bottom: 0;
          animation: fade 1s ease-in-out;

          @include widthQuery(930px) {
            width: 100%;
            max-width: 500px;
            height: auto;
          }

          @include heightQuery(960px) {
            height: 100%;
            width: auto;
          }
        }
      }
    }
  }
}

@keyframes profileAnimate {
  0% {
    border-radius: 90% 40% 30% 50%/50% 80% 30% 40%;
  }
  50% {
    border-radius: 50% 90% 70% 90%/30% 40% 70% 80%;
  }
  100% {
    border-radius: 90% 40% 30% 50%/50% 80% 30% 40%;
  }
}

@keyframes slideright {
  0% {
    transform: translateY(-20px);
    filter: blur(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    filter: blur(5px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

@keyframes fadeHalf {
  0% {
    opacity: 0;
    filter: blur(5px);
  }
  100% {
    opacity: 0.7;
    filter: blur(0px);
  }
}

@keyframes wave {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

@keyframes waveTwo {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
