* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0px;
  padding: 0px;
  color: white;
}

body {
  background-color: #120f23;
  overflow-x: hidden;
}

.App::-webkit-scrollbar {
  display: none;
}

.App #projects {
  justify-content: space-around;
  align-items: center;
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: bottom;
  z-index: 10;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: top;
  z-index: 10;
}
