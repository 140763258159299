@import "../../variables.scss";

.info-container {
  max-width: 700px;

  .page {
    ul {
      list-style: none;
      display: flex;
      align-items: center;

      li {
        display: inline-block;

        div {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: white;
          margin-right: 3px;
        }

        p {
          margin-left: 5px;
          font-weight: 600;
        }
      }
    }
  }

  .title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .info {
    white-space: pre-wrap;
    line-height: 25px;
    opacity: 0;
    animation: info 2s forwards;
    animation-delay: 2s;
    overflow: hidden;
    max-height: 0px;

    @include widthQuery(930px) {
      animation-delay: 1s;
    }

    @include widthQuery(630px) {
      animation-delay: 1s;
    }

    @include heightQuery(450px) {
      animation-delay: 1s;
    }
  }
}

@keyframes info {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000px; /* Adjust this value to match the content's height */
    opacity: 1; /* Fade in the content */
  }
}
