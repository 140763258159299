@import "../../variables.scss";

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background-color: #305879;

  .maxWidthAbout {
    max-width: 1700px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;

    @include widthQuery(830px) {
      flex-direction: column;
    }

    @include widthQuery(400px) {
      padding: 10px;
    }

    @include heightQuery(400px) {
      padding: 10px 60px 10px 10px;
    }

    .left-side-content {
      flex: 1;
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include widthQuery(630px) {
        padding-right: 0;
      }

      .info-container {
        @include widthQuery(630px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .page {
          ul {
            li {
              div {
                background-color: #a4d6d8;
              }

              p {
                color: #a4d6d8;
              }
            }
          }
        }

        .title {
          @include widthQuery(630px) {
            text-align: center;
          }
        }

        .info {
          @include widthQuery(630px) {
            text-align: center;
          }
        }
      }
    }

    .right-side-content {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 0%;
      opacity: 0;
      animation: about 1.5s forwards;
      animation-delay: 1s;
      flex: 1;
      /* 
      @include widthQuery(930px) {
        display: none;
      }

      @include widthQuery(630px) {
        display: none;
      }

      @include heightQuery(450px) {
        display: none;
      } */

      .profile-card {
        width: 400px;
        height: 700px;
        position: relative;
        background-image: url("../../Assets/AboutImg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        transition: all 1s;
        z-index: 1;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;

        @include widthQuery(1650px) {
          width: 300px;
          height: 450px;
        }

        @include widthQuery(830px) {
          margin-top: 40px;
        }

        @include heightQuery(630px) {
          width: 220px;
          height: 350px;
        }

        .infoCard h2,
        .infoCard p {
          color: #fff;
          opacity: 0;
          transition: all 0.6s;
        }

        .infoCard h2 {
          font-size: 18px;
        }

        .infoCard p {
          font-size: 15px;
        }

        &:hover .infoCard h2,
        &:hover .infoCard p {
          opacity: 1;
        }

        .infoCard::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // background: #764abc;
          background: #a4d6d8;
          transform: skew(30deg) translateX(100%);
          opacity: 0.3;
          z-index: -1;
          transition: all 0.6s ease;
        }
        .infoCard::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #a4d6d8;
          transform: skew(-30deg) translateX(100%);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
          opacity: 0.3;
          z-index: -1;
          transition: all 0.6s ease;
        }

        &:hover .infoCard::before {
          transform: skew(30deg) translateX(50%);
        }

        &:hover .infoCard::after {
          transform: skew(-30deg) translateX(40%);
          opacity: 0.7;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #a4d6d8;
          opacity: 0.3;
          transform: skew(30deg) translateX(85%);
          transition: all 0.6s ease;
          z-index: -1;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #a4d6d8;
          opacity: 0.3;
          transform: skew(-30deg) translateX(85%);
          transition: all 0.6s ease;
          z-index: -1;
        }
        &:hover:before {
          transform: skew(30deg) translateX(30%);
        }
        &:hover:after {
          transform: skew(-30deg) translateX(20%);
        }
      }
    }
  }
}

@keyframes showtext {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes about {
  0% {
    max-width: 0%;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    max-width: 100%;
    opacity: 1;
  }
}
